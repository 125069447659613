import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import icAddMacro from 'icons/toolbar/controls/ic-add-macro.svg';
import icCodeBlock from 'icons/toolbar/controls/ic-code-block.svg';
import icBlockquote from 'icons/toolbar/controls/ic-blockquote.svg';
import icTOC from 'icons/toolbar/controls/ic-toc.svg';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import { Dropdown } from '../../../Toolbar/Dropdown.component';
import { CODE_BLOCK_NODE_NAME } from '../../Editor/extensions/constants';

const getFocusedBlock = (editor: Editor) => ({
    isCodeBlock: editor.isActive(CODE_BLOCK_NODE_NAME),
    isBlockquote: editor.isActive('blockquote'),
});

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'CODE_BLOCK':
            return editor.chain().focus().insertContent({ type: CODE_BLOCK_NODE_NAME }).run();
        case 'BLOCKQUOTE':
            return editor.chain().focus().toggleBlockquote().run();
        case 'TOC':
            return editor.commands.insertTableOfContents();
        default:
            return false;
    }
};

export const MacrosComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<{ isCodeBlock: boolean; isBlockquote: boolean }, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const onChange = (value: string) => {
        setCurrentState(value);
    };

    const menuItems = [
        {
            value: 'CODE_BLOCK',
            tooltip: intl.formatMessage(messages.codeBlock),
            spriteSymbol: icCodeBlock,
            selected: !!value?.isCodeBlock,
            // dataTest: '',
        },
        {
            value: 'BLOCKQUOTE',
            tooltip: intl.formatMessage(messages.blockquote),
            spriteSymbol: icBlockquote,
            selected: !!value?.isBlockquote,
            // dataTest: '',
        },
        {
            value: 'TOC',
            tooltip: intl.formatMessage(messages.tableOfContents),
            spriteSymbol: icTOC,
            // dataTest: '',
        },
    ];

    return (
        <Dropdown<string>
            items={menuItems}
            title={intl.formatMessage(messages.macros)}
            compact
            defaultSprite={icAddMacro}
            onSelect={onChange}
            // dataTest=""
        />
    );
};
